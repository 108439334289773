<template>
  <ion-list v-if="resources.data.length > 0">
    <ion-list-header class="ion-margin-bottom">
      <h3>Classes</h3>
    </ion-list-header>

    <ion-item-sliding
      v-for="item in resources.data"
      :key="item.id"
      :ref="`items-${item.id}`"
    >
      <ion-item
        class="hydrated link"
        :router-link="{ name: 'attendance.show', params: { class: item.id } }"
      >
        <ion-label>
          <h2>{{ item.standard.name }} - {{ item.section.name }}</h2>
          <p>{{ item.students_count }} students</p>
        </ion-label>
        <ion-note slot="end">
          {{ item.teacher.user.user_detail.name }}
        </ion-note>
      </ion-item>
    </ion-item-sliding>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No classes found</p>
    </ion-list-header>
  </ion-list>
</template>
<script>
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItemSliding,
  IonItem,
  IonNote,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItemSliding,
    IonItem,
    IonNote,
  },
  data() {
    return {
      xhrError: {
        header: null,
        description: null,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["auth/user"];
    },
  },
};
</script>